import styled from "styled-components";

export const LoadingStyles = {};

LoadingStyles.Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 95;
  animation: 13500ms ease-in-out infinite color-change;
  @keyframes color-change {
    0% {
      background-color: #e64f00;
    }
    15% {
      background-color: #e6004d;
    }
    30% {
      background-color: #6138f5;
    }
    45% {
      background-color: #1f69ff;
    }
    60% {
      background-color: #008558;
    }
    75% {
      background-color: #ffaa00;
    }
    100% {
      background-color: #e64f00;
    }
  }
`;

LoadingStyles.Suspense = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

LoadingStyles.SuspenseImg = styled.img`
  margin-top: 10vh;
  width: 100%;
  max-width: 666px;
  @media (max-width: 600px) {
    max-width: 236px;
  }
`;

LoadingStyles.SuspenseTitle = styled.div`
  margin-top: 15vh;
  color: #ffffff;
  font-family: "XfinityBrown";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.88px;
  line-height: 37px;
  @media (max-width: 600px) {
    font-size: 20px;
    letter-spacing: 0.58px;
    line-height: 24px;
    text-align: center;
  }
`;

LoadingStyles.SuspenseText = styled.div`
  margin-top: 24px;
  color: #ffffff;
  font-family: "XfinityBrown";
  font-size: 15px;
  letter-spacing: 0.44px;
  line-height: 21px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 12px;
    letter-spacing: 0.35px;
    line-height: 21px;
    text-align: center;
  }
`;

LoadingStyles.TransparentOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 95;
  background: #ffffffe0;
`;

LoadingStyles.Box = styled.div`
  padding: 5% 5%;
  height: 60%;
  max-height: 468px;
  width: 50%;
  max-width: 536px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    height: 45%;
    max-height: 360px;
    width: 90%;
    max-width: 360px;
  }
`;

LoadingStyles.LoadingImage = styled.img`
  width: 50%;
  max-width: 200px;
  @media (max-width: 600px) {
    max-width: 124px;
  }
`;

LoadingStyles.LoadingTitle = styled.div`
  margin: 48px auto 0;
  color: #000000;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.88px;
  line-height: 37px;
  text-align: center;
  @media (max-width: 600px) {
    margin: 24px auto 0;
    font-size: 20px;
    letter-spacing: 0.58px;
    line-height: 24px;
  }
`;
LoadingStyles.LoadingSubtitle = styled.div`
  margin: 12px auto 0;
  color: #8b8b97;
  font-size: 15px;
  letter-spacing: 0.44px;
  line-height: 21px;
  text-align: center;
`;
