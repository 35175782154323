
const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerComponent("gltf-morph", {
  multiple: true,
  schema: {
    morphs: { type: "string", default: "" },
  }, //end schema

  init: function () {
    this.el.addEventListener("object3dset", () => {
      this.morpher();
    });
  },

  update: function () {
    this.morphs = [];
    const morphs = JSON.parse(this.data.morphs);
    for (const morphTarget in morphs) {
      if (morphs[morphTarget]?.morph) {
        try {
          this.morphs.push(JSON.parse(morphs[morphTarget].morph));
        } catch (e) {
        }
      }
    }
    this.morpher();
  },

  morpher: function () {
    var mesh = this.el.object3D;
    mesh.traverse((o) => {
      if (o.morphTargetInfluences && o.userData.targetNames) {
        for (const [i, morphTarget] of o.userData.targetNames.entries()) {
          const pos = this.morphs.findIndex((el) => el.name === morphTarget);
          if (pos > -1) o.morphTargetInfluences[i] = this.morphs[pos].value;
          else o.morphTargetInfluences[i] = 0;
        }
      }
    });
  },
});

AFRAME.registerComponent("render-texture", {
  schema: {
    texture: { type: "string", default: '' },
    beard: { type: "boolean", default: false },
  },
  init: function () {
    this.el.addEventListener("model-loaded", (e) => {
      const object3d = this.el.getObject3D("mesh");
      if (!object3d) return;
      object3d.traverse(async (currentNode) => {
        if (currentNode.isMesh) {
          if (currentNode.name.toLowerCase() === "beard" && !this.data.beard) {
            currentNode.visible = false;
          } else {
            new THREE.TextureLoader().load(this.data.texture, (texture) => {
              //Update Texture
              texture.flipY = false;
              let material = new THREE.MeshBasicMaterial({
                map: texture,
                skinning: true,
                morphTargets: true,
                transparent: currentNode.name === "Glasses" ? true : false,
              });
              currentNode.material = material;
              currentNode.visible = true;
            });
          }
        }
      });
    });
  },
  update: function () {
    const object3d = this.el.getObject3D("mesh");
    if (!object3d) return;
    object3d.traverse((currentNode) => {
      if (currentNode.isMesh) {
        if (currentNode.name.toLowerCase() === "beard" && !this.data.beard) {
          currentNode.visible = false;
        } else {
          new THREE.TextureLoader().load(this.data.texture, (texture) => {
            texture.flipY = false;
            let material = new THREE.MeshBasicMaterial({
              map: texture,
              skinning: true,
              morphTargets: true,
              transparent: currentNode.name === "Glasses" ? true : false,
            });
            currentNode.material = material;
            currentNode.visible = true;
          });
        }
      }
    });
  },
});

AFRAME.registerComponent("float", {
  schema: {
    frequency: { type: "number", default: 1.0 },
    amplitude: { type: "number", default: 250 },
    camInitialized: { type: "boolean", default: false },
  },
  init: function () {
    this.timer = new THREE.Clock();
    this.startTime = 0;
    this.elapsedTime = 0;
  },
  tick: function (t, dt) {
    // if (this.el.object3D.position.y > 1.35) {
    if (!this.prevPosition) {
      this.prevPosition = this.el.object3D.position;
    } else {
      const elapsedTime = this.timer.getElapsedTime();
      const newPos = Math.sin(elapsedTime * this.data.frequency);

      this.el.object3D.position.lerp(
        new THREE.Vector3(
          this.prevPosition.x,
          this.prevPosition.y + newPos / (this.data.amplitude * 1000),
          this.prevPosition.z
        ),
        0.5
      );
    }

    // if (this.data.camInitialized) this.elapsedTime += dt;
    // if (this.elapsedTime < 500) this.el.object3D.rotateY(0.2);
    // if (this.elapsedTime < 1000) this.el.object3D.rotateY(0.15);
    // if (this.elapsedTime < 1500) this.el.object3D.rotateY(0.1);
    // if (this.elapsedTime < 2000)
    //   this.el.object3D.rotateY(
    //     (Math.exp(this.elapsedTime / -100000) - 0.9) * 2.5
    //   );

    // if (this.elapsedTime < 2000) this.el.object3D.rotateY();
    // if (this.elapsedTime > 5000 && this.el.object3D.rotation.y !== 0)
    // this.el.object3D.rotateY(0.1);
    // update the values
    this.prevPosition = this.el.object3D.position;
  },
});

AFRAME.registerComponent("float-scale", {
  init: function () {
    this.timer = new THREE.Clock();
    this.el.object3D.scale.x = 0.65;
    this.el.object3D.scale.y = 0.35;
    this.el.object3D.scale.z = 0.2;
  },
  tick: function () {
    if (!this.prevScale) {
      this.prevScale = this.el.object3D.scale.y;
    } else {
      const elapsedTime = this.timer.getElapsedTime();
      const newPos = Math.sin(elapsedTime * 1.5);
      this.el.object3D.scale.x -= newPos / 1000;
    }
    // update the values
    this.prevScale = this.el.object3D.scale;
  },
});

AFRAME.registerComponent("remove-mat", {
  init: function () {
    this.el.addEventListener("model-loaded", (e) => {
      const object3d = this.el.getObject3D("mesh");
      if (!object3d) return;

      object3d.traverse((currentNode) => {
        if (currentNode.isMesh) {
          let material = new THREE.MeshStandardMaterial({
            skinning: true,
            morphTargets: true,
          });
          currentNode.material = material;
        }
      });
    });
  },
});
