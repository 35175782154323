import { LoadingStyles } from "./Loading.styles";
import LogoImage from "assets/icons/logo.png";
import { useAuth } from "common/auth";
import LoadingGif from "assets/logos/loading.gif";

const LoadingElement = ({ type }) => {
  const { newAvatar } = useAuth();

  return newAvatar && !type ? (
    <LoadingStyles.Overlay>
      <LoadingStyles.Suspense>
        <LoadingStyles.SuspenseImg src={LogoImage} alt="" />
        <LoadingStyles.SuspenseTitle>
          We're setting up your account
        </LoadingStyles.SuspenseTitle>
        <LoadingStyles.SuspenseText>
          This will take a few moments
        </LoadingStyles.SuspenseText>
      </LoadingStyles.Suspense>
    </LoadingStyles.Overlay>
  ) : (
    <LoadingStyles.TransparentOverlay>
      <LoadingStyles.Box>
        <LoadingStyles.LoadingImage src={LoadingGif} alt="" />
        <LoadingStyles.LoadingTitle>
        {type && type === 'saving' ? 'Updating your avatar...' : 'Loading avatar builder…' }
        </LoadingStyles.LoadingTitle>
        <LoadingStyles.LoadingSubtitle>
          This will take a few moments.
        </LoadingStyles.LoadingSubtitle>
      </LoadingStyles.Box>
    </LoadingStyles.TransparentOverlay>
  );
};

export default LoadingElement;
