import styled, { css } from "styled-components";

export const AppStyles = {};

AppStyles.Suspense = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

AppStyles.SuspenseImg = styled.img`
  margin-top: 10vh;
  width: 100%;
  max-width: 666px;
  @media (max-width: 600px) {
    max-width: 236px;
  }
`;

AppStyles.SuspenseTitle = styled.div`
  margin-top: 15vh;
  color: #ffffff;
  font-family: "XfinityBrown";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.88px;
  line-height: 37px;
  @media (max-width: 600px) {
    font-size: 20px;
    letter-spacing: 0.58px;
    line-height: 24px;
    text-align: center;
  }
`;

AppStyles.SuspenseText = styled.div`
  margin-top: 24px;
  color: #ffffff;
  font-family: "XfinityBrown";
  font-size: 15px;
  letter-spacing: 0.44px;
  line-height: 21px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 12px;
    letter-spacing: 0.35px;
    line-height: 21px;
    text-align: center;
  }
`;

AppStyles.Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

AppStyles.Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 96;
  animation: 13500ms ease-in-out infinite color-change;
  @keyframes color-change {
    0% {
      background-color: #e64f00;
    }
    15% {
      background-color: #e6004d;
    }
    30% {
      background-color: #6138f5;
    }
    45% {
      background-color: #1f69ff;
    }
    60% {
      background-color: #008558;
    }
    75% {
      background-color: #ffaa00;
    }
    100% {
      background-color: #e64f00;
    }
  }
`;

AppStyles.Intro = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  justify-content: flex-start;
  align-items: center;
  z-index: 95;
  /* padding: 60px 32px; */
  flex-direction: column;
  align-items: center;
  background-color: white;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

AppStyles.Container = styled.div``;

AppStyles.FloatingLogo = styled.img`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 202px;
  @media (max-height: 1050px) {
    display: none;
  }
`;

AppStyles.MobileLogo = styled.img`
  width: 142px;
  @media (min-width: 600px) {
    display: none;
  }
`;

AppStyles.Box = styled.div`
  width: 65%;
  max-width: 1175px;
  border: 6px solid #ececf3;
  display: flex;
  /* padding: 60px 32px; */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background-color: #ececf3;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.29);

  @media (max-width: 600px) {
    border: none;
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

AppStyles.Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

AppStyles.Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.88px;
  line-height: 37px;
  text-align: center;
  margin-top: 18px;
  @media (max-width: 600px) {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.58px;
    line-height: 24px;
    text-align: center;
    margin-top: 24px;
  }
`;
AppStyles.Text = styled.div`
  margin: 18px auto 0;
  height: 42px;
  width: 400px;
  color: #8b8b97;
  font-size: 18px;
  letter-spacing: 0.44px;
  line-height: 24px;
  text-align: center;
  @media (max-width: 600px) {
    margin: 12px auto 0;
    max-width: 336px;
    font-size: 15px;
    letter-spacing: 0.35px;
    line-height: 18px;
    text-align: center;
  }
`;

AppStyles.Image = styled.img`
  ${({ isMobile }) =>
    isMobile
      ? css`
          height: auto;
          margin: 2.5vh 0 0;
          width: 80%;
          @media (max-height: 1024px) {
            margin: 10vh 0 0;
            width: 90%;
            height: auto;
          }
          @media (max-height: 897px) {
            margin: 15vh 0 0;
            max-width: 360px;
            width: 90%;
            height: auto;
          }
          @media (max-height: 740px) {
            margin: 10vh 0 0;
            max-width: 360px;
            width: 90%;
            height: auto;
          }
          @media (max-height: 667px) {
            margin: 7vh 0 0;
            max-width: 350px;
            width: 88%;
            height: auto;
          }
        `
      : css`
          margin: 5vh 0 0;
          width: auto;
          height: 70%;
          @media (max-height: 1000px) {
            margin: 4vh 0 0;
            /* max-height: 620px; */
          }
          @media (max-height: 900px) {
            margin: 4vh 0 0;
            /* max-height: 556px; */
            height: 65%;
          }
          @media (max-height: 800px) {
            margin: 4vh 0 0;
            /* max-height: 436px; */
          }
          @media (max-height: 700px) {
            margin: 2vh 0 0;
            height: 60%;
            /* max-height: 360px; */
          }
          @media (max-height: 600px) {
            margin: 10vh 0 0;
            /* max-height: 360px; */
            height: 45%;
          }
        `}
`;

AppStyles.ButtonBlock = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  bottom: 80px;
  margin: 40px auto 64px;
`;

AppStyles.Button = styled.div`
  margin: 0 auto;
    width: 310px;
  padding: 16px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #0d0d0f;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.58px;
  /* line-height: 10px; */
  text-align: center;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 16px 40px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.47px;
    line-height: 19px;
    text-align: center;
  }
`;

AppStyles.InvisButton = styled.div`
  border: 2px solid #ececf3;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.58px;
  /* line-height: 10px; */
  text-align: center;
  padding: 20px 32px;
  visibility: hidden;
  @media (max-width: 600px) {
    display: none;
  }
`;

AppStyles.SkipButton = styled.div`
  border-radius: 10px;
  color: #0d0d0f;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.58px;
  text-align: center;
  padding: 8px 24px;
  cursor: pointer;
  @media (max-width: 1024px) {
    padding: 16px 40px;
    font-size: 16px;
    width: 298px;
    font-weight: bold;
    letter-spacing: 0.47px;
    line-height: 19px;
    text-align: center;
    margin: 12px 8px 0;
  }
`;

AppStyles.Fadein = styled.div`
  background-color: #ffffff;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.7s;
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
